/* * { margin: 0 !important } */

a { text-decoration: none !important; color: inherit !important; }

/* WHITE */
.bg-light       { background-color: #F9F7F7 !important; }
.text-light     { color: #F9F7F7 !important }
.btn-light      { background-color: #F9F7F7 !important; border-color: #F9F7F7 !important; }
.border-light   { border-color: #F9F7F7 !important; }

/* BLUE */
.bg-primary     { background-color: #112D4E !important; }
.text-primary   { color: #112D4E !important }
.btn-primary    { background-color: #112D4E !important; border-color: #112D4E !important; }
.border-primary { border-color: #112D4E !important; }

/* RED */
.bg-danger     { background-color: #810000 !important; }
.text-danger   { color: #810000 !important }
.btn-danger    { background-color: #810000 !important; border-color: #810000 !important; }
.border-danger { border-color: #810000 !important; }

/* MILD */
.bg-secondary     { background-color: #3F72AF !important; }
.text-secondary   { color: #3F72AF !important }
.btn-secondary    { background-color: #3F72AF !important; border-color: #3F72AF !important; }
.border-secondary { border-color: #3F72AF !important; }

/* MILD */
.bg-warning     { background-color: #F78812 !important; }
.text-warning   { color: #F78812 !important }
.btn-warning    { background-color: #F78812 !important; border-color: #F78812 !important; }
.border-warning { border-color: #F78812 !important; }

/* .bg-success     { background-color: #34BE82 !important; }
.text-success   { color: #34BE82 !important }
.btn-success    { background-color: #34BE82 !important; border-color: #34BE82 !important; }
.border-success { border-color: #34BE82 !important; } */

.bg-success     { background-color: #1E5128 !important; }
.text-success   { color: #1E5128 !important }
.btn-success    { background-color: #1E5128 !important; border-color: #1E5128 !important; }
.border-success { border-color: #1E5128 !important; }

/* DBE2EF as revered color */

.text-gradient {
    background: linear-gradient(to top, #3F72AF 0%, #112D4E 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
};

p { white-space: pre-wrap; }